<template>

	<div class="page bg-white" >
    <!-- 核销区 -->
		<div v-if="isPay" class="container">
			<div class="title">Tickets, Please</div>
			<div class="content radius">
				<div class="tips">
					<img :src="imgPath39 + '/pay_tips.png'" alt="" style="width: 100%;">
				</div>
				<div class="code radius">
					<vue-qr :text="qrCode.text" :correctLevel="3" :margin="20" colorDark="#000" colorLight="#fff" :dotScale="1"
					 :logoSrc="qrCode.logo" :logoScale="0.3" :size="300" style="width: 100%;">
					</vue-qr>
				</div>
			</div>
			<van-button type="primary" block class="font16 radius btn" @click="nextStep">Identification Card</van-button>
			<van-button type="primary" block class="font16 radius btn" @click="back">Back</van-button>
		</div>
    
    <!-- 购票选择取 -->
    <div v-else class="container">
    	<div class="title">Choose your ticket</div>
      <div class="ticket-list">
        <div v-for="(item,index) in tickets" :key="index" @click="showPopup(item.id)">
          <img :src="imgPath39 + item.img" class="item radius" />
        </div>
      </div>
      <div class="ticket-tips">
        <div class="en-font">
          <!-- <span class="block">Opening Hours:</span> -->
          <span class="block">3.16-18.2025 (9:00 AM - 5:30 PM) No Entry after 5:00 PM</span>
        </div>
        <div class="en-font margin-bottom">
          <span class="block">3.19.2025 [9:00 AM - 4:00 PM]</span>
          <span class="block">Tickets sales stop @2:00 PM, No Entry after 3:00 PM</span>
        </div>
        <div class="en-font margin-bottom">
          <span class="block">Please note all tickets are non-refundable & non-transferable.</span>
        </div>
      </div>
    </div>
    
    <!-- 购票提示弹框 -->
    <van-popup v-model="isShowPopup" class="ticket-popup">
      <div class="tips-box en-font">
        <div v-if="ticket_id == 1" class="row-tips">One person admission <span class="text-bold">1 day</span> with multiple entry/exit</div>
        <div v-if="ticket_id == 2" class="row-tips">One person admission <span class="text-bold block">2 consecutive days</span> with multiple entry/exit</div>
        <div v-if="ticket_id == 3" class="row-tips">One person admission <span class="text-bold block">4 consecutive days</span> with multiple entry/exit</div>
      </div>
      <van-button type="primary" block class="font16 radius btn btn1" @click="closePopup">Okay</van-button>
    </van-popup>
    
		<div class="footer">
			<bottom :mode="style.mode" :isCamp="isCamp" :level="style.level" color="#333" language="en"></bottom>
		</div>
  </div>
</template>

<script>
	import { validatePay } from '@/api/index.js';
	import { Popup  } from 'vant';
	import commonMixin from '@/view/process/mixin/common.js';
	import vueQr from "vue-qr";

	export default {
		mixins: [commonMixin],
		components: {
			vueQr,
      [Popup .name]: Popup,
		},
    data() {
      return {
        isShowPopup: false,
        // imgPath37: 'https://exhoss37.szcreativeweek.com/common/applet/ticket',
        imgPath39: 'https://exhoss39.szcreativeweek.com/applet/common/applet/ticket',
        tickets: [
          { id: 3, name: '全票', img: '/t0.jpg' }, // price: 100,
          // { id: 3, name: '四日联票', img: '/t3.jpg' }, // price: 150,
          // { id: 2, name: '两日联票', img: '/t2.jpg' }, // price: 120,
          // { id: 1, name: '单日票', img: '/t1.jpg' }, // price: 100,
        ],
				qrCode: {
					text: "", // 二维码内容
					logo: require('@/assets/img/szcw.jpg'), // logo图片路径
				},
        ticket_id: 0,
        isPay: false,
      }
    },
    created() {
			this.setLanguge('en'); // 设置语言环境
			
    },
    methods: {
      showPopup(id) {
        this.ticket_id = id;
        this.isShowPopup = true;
      },
      closePopup() {
        this.isShowPopup = false;
        // 用于登记小程序核销扫码
        this.qrCode.text = 'PAYS-' + this.unionid + '-' + this.ticket_id;
        
        this.isPay = true
      },
      back() {
        this.ticket_id = 0;
        this.isPay = false
      },
			nextStep() {
				this.$toast.loading({
					loadingType: 'spinner',
					forbidClick: true,
					duration: 0,
				});
				
				validatePay(this.unionid).then(res => {
					this.pageSwitch(res.data);
				}).catch(err => {
					err ? this.$toast(err.message) : this.$toast.clear();
				});
			}
    },
  }
</script>

<style lang="less" scoped>
  .en-font {
    /* 英文字体 */
    font-family: sans-serif;
  }
// PC
@media screen and (min-width: 751px) {
  .margin-bottom {
    margin-bottom: 20Px;
  }
  
  .page {
    color: #060211;
  }
  
  .title {
  	margin-top: 5vh;
  	margin-bottom: 3vh;
  	text-align: center;
  	font-size: 48Px;
  }
  
  .content {
  	width: 560Px;
  	padding: 60Px 0;
  	margin: 0 auto 60Px;
    background-color: #060211;
  	
  	.tips {
  		width: 85%;
  		margin: 0 auto 40Px;
  	}
  	
  	.code {
  		width: 360Px;
  		height: 360Px;
  		margin: 0 auto;
  		background-color: #fff;
  	}
    
  }
  
  .ticket-list {
    width: 520Px;
    min-height: 600Px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .item {
      display: block;
      width: 100%;
      // height: 228Px;
      height: auto;
      margin-bottom: 32Px;
    }
  }
  
  .ticket-tips {
    // width: 260px;
    width: 740Px;
    margin: 4vh auto 0;
    font-size: 24Px;
    line-height: 40Px;
    text-align: center;
  }
  
  .btn {
  	width: 560Px;
    height: 100Px;
  	margin: 0 auto 3vh;
    background-color: #060211;
    letter-spacing: 1Px;
    border: none;
  }
  .btn1 {
  	width: 360Px;
    height: 72Px;
    font-size: 28Px;
  }
  .ticket-popup {
    border-radius: 36Px;
  }
  .tips-box {
    width: 540Px;
    height: 240Px;
    
    .row-tips {
      margin: 60Px 84Px;
      text-align: center;
      line-height: 60Px;
      font-size: 32Px;
    }
  }
}
// 移动
@media screen and (max-width: 750px) {
  .margin-bottom {
    margin-bottom: 10px;
  }
  
  .page {
    color: #060211;
  }
  
  .title {
  	margin-top: 5vh;
  	margin-bottom: 3vh;
  	text-align: center;
  	font-size: 24px;
  }
  
  .content {
  	width: 280px;
  	padding: 30px 0;
  	margin: 0 auto 30px;
    background-color: #060211;
  	
  	.tips {
  		width: 85%;
  		margin: 0 auto 20px;
  	}
  	
  	.code {
  		width: 180px;
  		height: 180px;
  		margin: 0 auto;
  		background-color: #fff;
  	}
    
  }
  
  .ticket-list {
    width: 260px;
    min-height: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .item {
      display: block;
      width: 100%;
      // height: 114px;
      height: auto;
      margin-bottom: 16px;
    }
  }
  
  .ticket-tips {
    // width: 260px;
    width: 370px;
    margin: 4vh auto 0;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }
  
  .btn {
  	width: 280px;
    height: 50px;
  	margin: 0 auto 3vh;
    background-color: #060211;
    letter-spacing: 1Px;
    border: none;
  }
  .btn1 {
  	width: 180px;
    height: 36px;
  }
  .ticket-popup {
    border-radius: 18px;
  }
  .tips-box {
    width: 270px;
    height: 120px;
    
    .row-tips {
      margin: 30px 42px;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
    }
  }
}  
  
</style>
