<template>
	<div class="page" :style="{ 'background-color': style.btnColor || '#048DAD'}">
		<div class="container">
			<div class="logo" >
				<img :src="style.logo || logoSrc" alt="" class="fill">
			</div>
			<div class="title">您的信息已提交成功</div>
			<div class="content">
				<!-- <div>由于新冠肺炎疫情影响，所有人员需实名入场,</div> -->
				<div>您的实名信息正在审核中，请耐心等待！</div>
				<div><b>审核结果将以微信公众号消息/短信的形式通知您！</b></div>           
			</div>
		</div>
		<div class="footer">
			<bottom :mode="style.mode" :level="style.level" color="#ffffff"></bottom>
		</div>
	</div>
</template>

<script>

	import commonMixin from '@/view/process/mixin/common.js';
	import waitingMixin from '@/view/process/mixin/waiting.js';

	export default {
		mixins: [commonMixin, waitingMixin],
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('zh'); // 设置语言环境
		},
		methods: {

		},
	}
</script>

<style lang="less" scoped>
	@color: #9F9D9D;
	// @color: #6D6D6D;
	
	.logo {
		width: 180px;
		margin: 60px auto 30px;
	}
	.title {
		margin: 5vh auto 8vh;
		text-align: center;
		color: @color;
		font-family: Source Han Sans CN;
		font-size: 20px;
		line-height: 30px;
		color: #fff;
	}

	.content {
		width: 100%;
		margin: 20px auto;
		color: #fff;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 40px;
		font-size: 14px;
		text-align: center;
	}
</style>
