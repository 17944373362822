<!-- 授权页面：请求微信授权信息 -->
<template>
	<div class="page">
    
    <div v-if="isEnd" class="tips">报名已结束！</div>
    
    <template v-else>
      <van-loading size="30px" type="spinner" :color="color" vertical>
        <span class="font14" :style="{color: color}">Loading...</span>
      </van-loading>
    </template>

	</div>
</template>

<script>
	import { Loading, Dialog } from 'vant';
	import localStore from 'storejs'; // localStorage的二次封装(可直接存储与获取对象)
  import indexWxMixin from '@/view/process/mixin/index_wx.js';
  import {uuid} from '@/utils/utils.js';

	export default {
		mixins: [indexWxMixin],
		components: {
			[Loading.name]: Loading,
			[Dialog.name]: Dialog,
		},
		data() {
			return {
				color: '#969799',
        		isEnd: false, // 报名已结束
			}
		},
		created() {
      
      if (!this.isEnd) {
        // 用户扫码: 首页浏览器会访问后台的初级数据处理页面，然后数据处理页面重定向到前端的auth页面
        // 重定向时传参: limitCode、unionid
        let {unionid = '', l = ''} = this.$route.query; // 接收传递过来的参数
        this.handleJump(unionid, l);
      }

		},
		methods: {
			// 页面转换
			handleJump(unionid, l) {
        
        // 替换掉初始化的unionid
        unionid = unionid == '00000000-0000-0000-0000-000000000000' ? uuid() : unionid;
        
				if (unionid && l) {
          localStorage.setItem('l', l); // 本地存储取limitCode
          localStorage.setItem('unionid', unionid); // 本地存储取unionid
				} else {
					// 跳转到错误提示页面
					// this.$router.replace({
					// 	path: '/errorTips',
					// 	query: {
					// 		errorMessage: '参数缺失, 无法登记 <br> Missing parameter,<br>Unable to register'
					// 	}
					// })
				}
        this.getRegisterData(unionid, l); // 重定向页面
			},

		},
	}
</script>

<style lang="less" scoped>
	.page {
		justify-content: center;
		align-items: center;
	}
  
  .tips {
    font-size: 18Px;
    font-weight: bold;
  }  

</style>
