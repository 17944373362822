<template>
	<div class="page">
		<div class="row-scroll-box">
			
			<!-- 国家地区列表栏 -->
			<div class="country-container" :class="isShow?'show':''">
				<van-search v-model.trim="keyword" show-action shape="round" placeholder="请输入搜索内容" @input="inputSearch" @clear="inputSearch" @cancel="close" />
				<div class="location-list-container">
					<div v-for="(item,idx) in locationOptions" :key="idx" :data-code="item.national_code" :data-name="item.area_name" class="location-item" @click="chooseLocation">
						<div class="location_name text-cut">{{item.area_name}}</div>
						<div>+{{item.national_code}}</div>
					</div>
				</div>
			</div>

			<!-- Main -->
			<div :style="{'background-color': `${style.bgColor || '#fff'}`, 'background-image': `url('https://exh40.oss-cn-shenzhen.aliyuncs.com/test/config/6202/26D9E05B-038F-452D-A6FF-B17C92FEADFB_zh.jpg')`,'background-size':'100% 100%'}" class="main flex flex-direction justify-between">
				<!-- <div class="logo">
					<img :src="style.logo || logoSrc" alt="" class="img">
				</div> -->
				<div class="container" style="padding-top:40vh">
					<div class="flow-type">
						<img :src="style.flowTypeImg || flowTypeSrc" alt="" class="img">
					</div>
					<div class="content">
						<!-- VIP -->
						<!-- <div v-if="style.mode === 1" class="title">
							<img :src="style.titleImg" alt="">
						</div> -->
						<van-cell :title="locationName" :value="'+'+locationCode" is-link arrow-direction="down"  @click="showCountry"  class="phone font13 radius" />
						<van-field v-model="phone" ref="phone" type="tel" maxlength="11" placeholder="请输入手机号" class="phone font13 radius" />
						<div class="flex justify-between input-box">
							<van-field v-model="codeVal" ref="codeVal" type="tel" maxlength="6" center placeholder="验证码" class="code font13 radius" />
							<van-button v-if="!isSend" type="primary" color="black" class="code-btn font12 radius flex align-center justify-center" @touchstart="sendCode">
								<span class="letter-spacing">获取验证</span><br/>
								<span>Get Code</span>
							</van-button>
							<van-button v-else type="primary" color="black" disabled class="code-btn font13 radius">
								<van-count-down ref="countDown" class="text-white" :time="time" format="ss s" @finish="isSend = false" />
							</van-button>
						</div>
						<van-button type="primary" block color="black" class="font16 radius letter-spacing next-btn" @touchstart="nextStep">立即领票</van-button>
						
						<!-- <div class="tips">
							<span v-if="style.tips && style.tips[0]">{{style.tips[0]}}</span>
							<span v-if="style.tips && style.tips[1]">{{style.tips[1]}}</span>
							<span v-if="style.tips && style.tips[2]">{{style.tips[2]}}</span>
							<span v-if="style.tips && style.tips[3]">{{style.tips[3]}}</span>
						</div> -->
					</div>
				</div>
				
				<div class="footer">
					<bottom :mode="style.mode" :level="style.level" language="zh" :isHome="true"></bottom>
				</div>
				
			</div>
		
		</div>
    
    <div v-if="isShowErrTips" class="mask-layer">
      <div class="tips-box">
        <div class="tips">提示:</div>
        <div class="">
          <div class="tips-title">对不起，你曾经用微信小程序报名过,</div>
          <div class="tips-title">请用微信小程序找回你的入场码</div>
        </div>
        <div class="close-btn" :style="{'background-color': style.mainColor || btnColor}" @click="closeBindTips">Close</div>
      </div>
    </div>
    
	</div>
</template>

<script>
	
	import commonMixin from '@/view/process/mixin/common.js';
	import loginMixin from '@/view/process/mixin/login.js';

	export default {
		mixins: [commonMixin, loginMixin],
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('zh'); // 设置语言环境
			this.getCountryList('zh'); // 获取国家列表
			this.setDefaultLocation(); // 设置默认地区
		},
		methods: {
			
		}
	}
</script>

<style lang="less" scoped>
	@var: 3vh;
  
  .mask-layer {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    z-index: 10;
  }
   
  .tips-box {
    position: fixed;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 280px; 
    height: 240px;
    padding: 20px 15px;
    background-color: #fff;
    border-radius: 6px;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .tips-box .tips {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .tips-title {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 26px;
  }
  .close-btn {
    font-size: 20px;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    width: 160px;
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    margin: 30px auto 0;
  }
	
	.page {
		display: block;
		overflow-x: hidden;
		background-color: #fff;
	}
	.row-scroll-box {
		width: 200vw;
		display: flex;
	}
	.country-container {
		background-color: #FFFFFF;
		width: 100vw;
		height: calc(100vh - constant(safe-area-inset-bottom)); /* 兼容 IOS < 11.2 */
		height: calc(100vh - env(safe-area-inset-bottom)); /* 兼容 IOS > 11.2 */
		margin-left: -100vw;
		transition: margin-left 500ms;
		padding: 5px 10px 5px 0;
		overflow-x: hidden;
		
		.location-list-container {
		  margin: 0 20px;
		}
		
		.location-item {
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  height: 45px;
		  color: #333;
			font-size: 14px;
		}
		
		.location-item + .location-item {
		  border-top: 1px solid #eee
		}
		
		.location_name {
		  width: 250px;
		}
	}
	.show {
		margin-left: 0;
	}
	
	.main {
		width: 100vw;
		min-height: 100vh;
		background-size: 100% 100%;
	}
	
	.img {
		width: 100%;
	}
	
	.logo {
		width: 160px;
		margin: 40px auto 20px;
	}
	.flow-type {
		width: 300px;
		margin: 0 auto 25px;
	}
	
	.container {
		width: 100%;
		margin: 0 auto;
		
		.title {
			width: 120px;
			margin: 0 auto 3vh;
		}
		
		.tabs {
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin-bottom: @var;
			font-size: 20px;
			font-weight: bold;

			span {
				padding-bottom: 2px;
			}

			.active {
				border-bottom-style: solid;
				border-bottom-width: 4px;
			}
		}
		
		.content {
			width: 255px;
			margin: 0 auto ;
			
			.phone {
				display: flex;
				align-items: center;
				height: 40px;
				margin-bottom: @var;
			}

			.code {
				height: 40px;
				margin-right: 14px;
			}
			
			/deep/ .van-cell {
				padding: 10px 10px 10px 15px;
				
				.van-cell__title {
					flex: 3;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			 .van-cell__value {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				
				.van-cell__right-icon {
				  margin-left: 10px;
				}
			}
			
			/deep/ .van-button--normal {
				padding: 0;
			}

			.code-btn {
				height: 40px;
				width: 180px;
			}
			
			.input-box {
				margin-bottom: @var;
			}
			
			.next-btn {
				height: 40px;
				margin-bottom: 6vh;
			}
			.tips {
				color: #fff;
				margin-bottom: 2vh;
				
				span {
					display: block;
					font-size: 12px;
					line-height: 20px;
					text-align: center;
				}
			}
			
		}

	}
	
</style>
