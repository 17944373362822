<template>
	<div class="page">
		<div class="row-scroll-box">
			
			<!-- 国家地区列表选择栏 -->
			<div class="country-container" :class="isShow?'show':''">
				<van-search v-model.trim="keyword" show-action shape="round" placeholder="Location" action-text="Cancel" @input="inputSearch" @clear="inputSearch" @cancel="close" />
				<div class="country-box">
          <div class="location-list-container">
            <div v-for="(item,idx) in locationOptions" :key="idx" :data-code="item.national_code" :data-name="item.area_name" class="location-item" @click="chooseLocation">
              <div class="location_name text-cut">{{item.area_name}}</div>
              <div>+{{item.national_code}}</div>
              <div style="width: 24px;">
                <van-icon v-if="locationName == item.area_name" name="checked" color="#060211" class="checked-icon" />
              </div>
            </div>
          </div>
				</div>
			</div>
			<!-- Main -->
			<div :style="{'background-image': `url(${style.homeBgImg})`}" class="main flex flex-direction justify-between">
      
				<div class="container">
          
					<div class="flow-type">
						<img :src="style.flowTypeImg || flowTypeSrc" alt="" class="fill">
					</div>
					<div class="content">
            <div class="input-box">
              <div class="label">Phone</div>
              <div class=" flex">
                <div class="location-container" @click="showCountry">
                  <span class="location-code">+{{locationCode}}</span>
                  <van-icon name="play" class="down-icon" />
                </div>
                <van-field v-model="phone" ref="phone" type="tel" maxlength="20" placeholder="Enter phone no." class="phone font14 radius" />
              </div>
            </div>

            <!-- 验证码 -->
            <div class="flex justify-between">
              <div class="code-box input-box">
                <div class="label">Code</div>
                <van-field v-model="codeVal" ref="codeVal" type="tel" maxlength="6" center placeholder="Enter code" class="code font14 radius" />
              </div>
              <!-- <van-button v-if="!isSend" type="primary" :color="style.btnColor" class="code-btn font14 radius flex align-center justify-center" @touchstart="sendCode"> -->
              <van-button v-if="!isSend" type="primary" :color="style.btnColor" class="code-btn font14 radius flex align-center justify-center" @click="sendCode">
                <span>Get Code</span>
              </van-button>
              <van-button v-else type="primary" :color="style.btnColor" disabled class="code-btn font14 radius">
                <van-count-down ref="countDown" class="text-white" :time="time" format="ss s" @finish="isSend = false" />
              </van-button>
						</div>
            
					</div>
          
          <button class="next-btn1 font16 radius letter-spacing" :style="{'background-color': style.btnColor}"  @click="nextStep"><span class="en-font">Next</span></button>
          <van-button type="primary" block :color="style.btnColor" class="next-btn font16 radius letter-spacing" @touchstart="nextStep"><span class="en-font">Next</span></van-button>
				</div>
				
        <!-- 页脚 -->
				<div class="footer">
					<bottom :mode="style.mode" :isCamp="isCamp" color="#000" :level="style.level" language="en"></bottom>
				</div>
				
			</div>
		
		</div>
    
    <!-- 错误提示: 微信小程序报名过, 请用微信小程序找回你的入场码 -->
    <div v-if="isShowErrTips" class="mask-layer">      
      <div class="tips-box">
        <div class="tips">Tips:</div>
        <div class="en-font">
          <div class="tips-text">Sorry!</div>
          <div class="tips-text">you used to sign up with wechat applet.</div>
          <div class="tips-text">Please use wechat applet to find your admission code</div>
        </div>
        <div class="close-btn" :style="{'background-color': style.mainColor}" @click="closeBindTips">Close</div>
      </div>
    </div>
    
    <!-- 观展小贴士 -->
    <div v-if="isShowTips && needPay == 1" class="mask-layer">      
      <div class="tips-box">
        <div class="tips-title margin-bottom">Exhibition Reminders</div>
        <div class="en-font margin-bottom">
          <div class="tips-content text-bold">Opening Hours:</div>
          <div class="tips-content">3.16-18.2025 [9:00 AM - 5:30 PM]</div>
          <div class="tips-content">No Entry after 5:00 PM</div>
        </div>
        <div class="en-font margin-bottom">
          <div class="tips-content">3.19.2025 [9:00 AM - 4:00 PM]</div>
          <div class="tips-content">Tickets sales stop @2:00 PM, No Entry after 3:00 PM</div>
        </div>
        <!-- <div class="en-font margin-bottom">
          <div class="tips-content text-bold">All visitors must show government issued ID, QR health code and travel itinerary card upon entry to exhibition grounds.</div>
        </div> -->
        <div class="en-font margin-bottom">
          <div class="tips-content text-bold">guests under age 18 must be accompanied by a parent or guardian.</div>
        </div>
        <div class="en-font">
          <div class="tips-content font10">※ All Tickets are non-refundable & non-transferable; Do not buy your ticket through unofficial channels.</div>
        </div>
        <div class="close-btn" :style="{'background-color': style.mainColor}" @click="closeBindTips">Okay</div>
      </div>
    </div>
    
  </div>
</template>

<script>

	import commonMixin from '@/view/process/mixin/common.js';
	import loginMixin from '@/view/process/mixin/login.js';

	export default {
		mixins: [commonMixin, loginMixin],
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('en'); // 设置语言环境
			this.getCountryList('en'); // 获取国家列表
      this.setDefaultLocation(); // 设置默认地区
		},
		methods: {
			
		},
	}
</script>

<style lang="less" scoped>
	@var: 3vh;
  .en-font {
    /* 英文字体 */
    font-family: sans-serif;
  }
  
// PC
@media screen and (min-width: 751px) {
  .font10 {
    font-size: 20Px!important;
  }
  .font14 {
    font-size: 28Px!important;
  }
  .font16 {
    font-size: 32Px!important;
  }
  .margin-bottom {
    margin-bottom: 40Px;
  }
  .fill {
    width: 100%;
    height: 100%;
  }
  
  .mask-layer {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    z-index: 10;
  }
   
  .tips-box {
    position: fixed;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 600Px; 
    min-height: 560Px;
    padding: 40Px 30Px;
    background-color: #fff;
    border-radius: 12PX;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .tips-box .tips {
    font-size: 36Px;
    font-weight: bold;
    margin-bottom: 40Px;
  }
  .tips-text {
    font-size: 24Px;
    letter-spacing: 1px;
    line-height: 52Px;
  }
  .tips-title {
    font-size: 28Px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    line-height: 52Px;
    // color: #888;
  }
  .tips-content {
    // color: #999;
    text-align: left;
    font-size: 24Px;
    line-height: 40Px;
  }
  .close-btn {
    font-size: 36Px;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    width: 320Px;
    height: 80Px;
    line-height: 80Px;
    border-radius: 12Px;
    margin: 60Px auto 0;
  }
  
  .page {
  	display: block;
  	overflow-x: hidden;
  	background-color: #fff;
  }
  .row-scroll-box {
  	width: 1500PX;
  	display: flex;
  }
  .country-container {
  	background-color: #FFFFFF;
  	width: 750Px;
  	height: calc(100vh - env(safe-area-inset-bottom)); /* 兼容 IOS > 11.2 */
  	margin-left: -750Px;
  	transition: margin-left 500ms;
  	padding: 20Px 20Px 10Px 0;
    box-sizing: border-box;
  	overflow-x: hidden;
    
    /deep/ .van-search {
      padding: 0 40Px 10Px;
      
      .van-search__content {
        padding-left: 20Px;
      }
      .van-cell {
        padding: 0 5Px 0 0;
        font-size: 28Px;
        line-height: 56Px;
      }
      .van-icon {
        font-size: 36Px;
      }
      
      .van-search__action {
        padding: 0 20Px;
        font-size: 36Px;
        line-height: 56Px;
      }
    }
  	.country-box {
  	  height: calc(100% - 80Px);
  	  overflow-y: scroll;
  	  -webkit-overflow-scrolling: touch;
  	}
  	.location-list-container {
  	  margin: 0 24Px 0 40Px;
  	}
  	
  	.location-item {
  	  display: flex;
  	  justify-content: space-between;
  	  align-items: center;
  	  height: 90Px;
  	  color: #333;
  		font-size: 28Px;
  	}
  	
  	.location-item + .location-item {
  	  border-top: 1px solid #eee
  	}
  	
  	.location_name {
  	  width: 500Px;
  	}
    
    .checked-icon {
      font-size: 40Px;
      margin-top: 6Px;
    }
  }
  .show {
  	margin-left: 0;
  }
  
  .main {
  	width: 750Px;
  	min-height: 100vh;
    // background-size: 88% auto;
    background-size: 100% auto;
    // background-position: center 2vh;
    background-position: center top;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    font-family: Source Han Sans CN;
  }
  
  .container {
    margin: 660Px auto 0;
    width: 680Px;
    height: 550Px;
    
    .flow-type {
      width: 600Px;
      height: 50Px;
      margin-left: 30Px;
      margin-bottom: 70Px;
    }
  	
  	.content {
  		width: 100%;
  		
      .label {
        color: #333;
        font-size: 28Px;
        line-height: 40Px;
        font-weight: bold;
      }
      
      .input-box {
        background-color: rgb(245, 245, 245);
        border-radius: 8Px;
        padding: 12Px 20Px 12Px 30PX;
        height: 120Px;
        margin-bottom: 30Px;
      }
      
      .code-box {
        width: 450Px;
      }
      
      .location-container {
        width: 160Px;
        display: flex;
        align-items: center;
        margin-right: 30PX;
      }
      
      .location-code {
        display: block;
        width: auto;
        font-size: 28Px;
        color: #333;
        margin-right: 20Px;
        font-weight: bold;
      }
      .down-icon {
        transform: rotate(90deg);
        font-size: 32PX;
        width: 32PX;
        height: 30P;
        color: #333;
      }
  		.phone,
      .code {
  			display: flex;
  			align-items: center;
  			height: 60Px;
        background-color: transparent;
        padding: 0!important;
        // font-weight: bold;
  		}
  
  		/deep/ .van-cell {
  			padding: 20Px 20Px 20Px 30Px;
  			
  			.van-cell__title {
  				flex: 3;
  				text-overflow: ellipsis;
  				white-space: nowrap;
  				overflow: hidden;
  			}
  		 .van-cell__value {
  				text-overflow: ellipsis;
  				white-space: nowrap;
  				overflow: hidden;
  			}
  			
  			.van-cell__right-icon {
  			  margin-left: 20Px;
  			}
  		}
  
  		/deep/ .van-button--normal {
  			padding: 0;
  		}
  
  		.code-btn {
  			width: 210Px;
  			height: 120Px;
  		}
  		
  	}
  
    .next-btn {
      display: none;
    }
    .next-btn1 {
      display: block;
      width: 680Px;
      margin: 0 auto 80Px;
      height: 100Px;
      border: none;
      cursor: pointer;
      color: #FFFFFF;
    }
  }
  
  
}
// 移动
@media screen and (max-width: 750px) {
  
  .font10 {
    font-size: 10px!important;
  }
  .margin-bottom {
    margin-bottom: 20px;
  }
  .fill {
    width: 100%;
    height: 100%;
  }
  
	.mask-layer {
	  position: fixed;
	  left: 0;
	  top: 0;
	  background-color: rgba(0, 0, 0, 0.5);
	  width: 100vw;
	  height: 100vh;
	  z-index: 10;
	}
   
  .tips-box {
    position: fixed;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 300px; 
    min-height: 280px;
    padding: 20px 15px;
    background-color: #fff;
    border-radius: 6px;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .tips-box .tips {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .tips-text {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 26px;
  }
  .tips-title {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    line-height: 26px;
    // color: #888;
  }
  .tips-content {
    // color: #999;
    text-align: left;
    font-size: 12px;
    line-height: 20px;
  }
  .close-btn {
    font-size: 18px;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    width: 160px;
    height: 40px;
    line-height: 40px;
    border-radius: 6px;
    margin: 30px auto 0;
  }
  
	.page {
		display: block;
		overflow-x: hidden;
		background-color: #fff;
	}
	.row-scroll-box {
		width: 200vw;
		display: flex;
	}
	.country-container {
		background-color: #FFFFFF;
		width: 100vw;
		height: calc(100vh - constant(safe-area-inset-bottom)); /* 兼容 IOS < 11.2 */
		height: calc(100vh - env(safe-area-inset-bottom)); /* 兼容 IOS > 11.2 */
		margin-left: -100vw;
		transition: margin-left 500ms;
		padding: 5px 10px 5px 0;
		overflow-x: hidden;
		
    .country-box {
      height: calc(100% - 60px);
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
		.location-list-container {
		  margin: 0 12px 0 20px;
		}
		
		.location-item {
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  height: 45px;
		  color: #333;
			font-size: 14px;
		}
		
		.location-item + .location-item {
		  border-top: 1px solid #eee
		}
		
		.location_name {
		  width: 250px;
		}
    
    .checked-icon {
      font-size: 20px;
      margin-top: 3px;
    }
	}
	.show {
		margin-left: 0;
	}
	
	.main {
		width: 100vw;
		min-height: 100vh;
    // background-size: 88% auto;
    background-size: 100% auto;
    // background-position: center 2vh;
    background-position: center top;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    font-family: Source Han Sans CN;
	}
	
	.container {
    // margin: 42vh auto 0;
    margin: 340px auto 0;
    width: 340px;
    // height: 210px;
    
    .flow-type {
      width: 300px;
      height: 25px;
      margin-left: 15px;
      margin-bottom: 35px;
    }
		
		.content {
			width: 100%;
			
      .label {
        color: #333;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
      }
      
      .input-box {
        background-color: rgb(245, 245, 245);
        border-radius: 4px;
        padding: 6px 10px 6px 15px;
        height: 60px;
        margin-bottom: 15px;
      }
      
      .code-box {
        width: 225px;
      }
      
      .location-container {
        width: 80px;
        display: flex;
        align-items: center;
        margin-right: 15px;
      }
      
      .location-code {
        display: block;
        width: auto;
        font-size: 14px;
        color: #333;
        margin-right: 10px;
        font-weight: bold;
      }
      .down-icon {
        transform: rotate(90deg);
        font-size: 16px;
        width: 16px;
        height: 15px;
        color: #333;
      }
			.phone,
      .code {
				display: flex;
				align-items: center;
				height: 30px;
        background-color: transparent;
        padding: 0!important;
        // font-weight: bold;
			}

			/deep/ .van-cell {
				padding: 10px 10px 10px 15px;
				
				.van-cell__title {
					flex: 3;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			 .van-cell__value {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				
				.van-cell__right-icon {
				  margin-left: 10px;
				}
			}

			/deep/ .van-button--normal {
				padding: 0;
			}

			.code-btn {
				width: 105px;
				height: 60px;
			}
			
			
		}

    .next-btn1 {
      display: none;
    }
    .next-btn {
      width: 340px;
      margin: 6px auto 20px;
      height: 55px;
    }
    
	}
}  
</style>
